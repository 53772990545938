<template>
  <div class="dynamicDetalis">
    <img class="w1" src="@/assets/w1.png" alt="">
    <img class="w2" src="@/assets/w2.png" alt="">
    <img class="w3" src="@/assets/w3.png" alt="">
    <div class="mianbao">
      <div @click="onUrl('/')">{{$t('dynamic.home')}}</div>
      <div>></div>
      <div @click="onUrl('/dynamic')">{{$t('dynamic.bannerText')}}</div>
      <div>></div>
      <div>{{$t('dynamic.xiangqin')}}</div>
    </div>
    <div class="content" v-if="dydetailsdat.detail">
      <div class="title">{{dydetailsdat.detail.title}} </div>
      <div class="time">{{dydetailsdat.detail.happen_time.split(' ')[0]}}</div>
      <div class="main">
        <div v-html="dydetailsdat.detail.content"></div>
      </div>
    </div>
    <div class="tuijian" v-if="dydetailsdat.more">
      <div class="title">{{$t('dynamic.moreList')}}</div>
      <div class="itemBox">
        <div class="item" v-for="(item,index) in dydetailsdat.more" :key="index" @click="dynamicDetalis(item.id)">
          <div class="imgBox">
            <img class="img" :src="baseHostUrl + item.imgurl" alt="">
          </div>
          <div class="text">{{item.title}}</div>
          <div class="time">{{item.happen_times}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {baseHostUrl,getdydetailsdata} from '@/utils/index.js'
export default {
  name: 'dynamicDetalis',
  components: {

  },
  data () {
    return {
      baseHostUrl: '',
      dydetailsdat: {}
    }
  },
  computed: {
  },
  mounted() {
    this.getdydetailsdata(this.$route.query.id)
    // this.getdydetailsdata(this.$route.params.id)
    this.baseHostUrl = baseHostUrl()
  },
  methods: {
    onUrl(url) {
      this.$router.push(url)
    },
    dynamicDetalis(id) {
      this.$router.push('/dynamicDetalis?id=' + id)
    },
    async getdydetailsdata(id) {
      let data = {
        type: localStorage.getItem('language').toLowerCase(),
        dynamics_id: id
      }
      let res = await getdydetailsdata(data)
      this.dydetailsdat = res.data
    },
  },
  watch: {
　　// 利用watch方法检测路由变化：
　　'$route': function (to, from) {
　　　　// 拿到目标参数 to.query.id 去再次请求数据接口
　　　　this.getdydetailsdata(to.query.id)
　　}
}
}
</script>
<style scoped>
@media screen and (max-width:750px){
  .main img{
    width: 100% !important;
    height: auto !important;
  }
}

</style>
<style lang="scss" scoped>
.dynamicDetalis{
  background: #F6F7F9;
  position: relative;
  .w1{
    position: absolute;
    top: 0;
    left: 0;
    width: 20vw;
  }
  .w2{
    position: absolute;
    top: 30%;
    right: 0;
    width: 20vw;
  }
  .w3{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20vw;
    z-index: 0;
  }
}

.mianbao{
  width: 63vw;
  margin: auto;
  font-size: 0.8vw;
  color: #999999;
  padding: 1.5vw 0;
  display: flex;
  align-items: center;
  div{
    margin: 0 8px 0 0;
    cursor: pointer;
  }
}
.content{
  width: 63vw;
  margin: auto;
  padding: 1vw 0;
  border-bottom: solid 1px #EEEEEE;
  position: relative;
  z-index: 1;
  .title{
    font-size: 1.4vw;
    font-weight: 700;
    color: #333333;
  }
  .time{
    font-size: 0.8vw;
    color: #999999;
    margin: 1vw 0 1.5vw 0;
  }
  .main{
    font-size: 0.85vw;
    color: #666666;
    line-height: 2;
    
  }
}
.tuijian{
  width: 63vw;
  margin: auto;
  color: #999999;
  padding: 4vw 0;
  position: relative;
  z-index: 1;
  .title{
    font-size: 1.2vw;
    color: #127CC0;
    font-weight: 600;
  }
  .itemBox{
    display: flex;
    justify-content: space-between;
    padding: 2vw 0 3vw 0;
    .item{
      flex: 0 0 32%;
      cursor: pointer;
      .imgBox{
        overflow: hidden;
        width: 100%;
        .img{
          width: 100%;
          height: 12.5vw;
          transition: all 0.3s ease-out;
        }
        &:hover{
          .img{
            transform: scale(1.1);
            transition: all 0.3s ease-out;
          }
        }
      }
      
      .text{
        font-size: 1vw;
        color: #333;
        padding: 1vw 0 0.5vw 0;
      }
      .time{
        font-size: 0.75vw;
        color: #999999;
      }
    }
  }
}
@media screen and (max-width:750px){
  .mianbao{
    display: none;
  }
  .content{
    width: auto;
    padding: 6vw;
    .title{
      font-size: 5vw;
      font-weight: 700;
      color: #333333;
    }
    .time{
      font-size: 3vw;
      color: #999999;
      margin: 3vw 0 4vw 0;
    }
    .main{
      font-size: 3.8vw;
      color: #666666;
      line-height: 2;
    }
  }
  .tuijian{
    width: auto;
    margin: auto;
    color: #999999;
    padding: 6vw 6vw 4vw 6vw;
    position: relative;
    z-index: 1;
    .title{
      font-size: 5vw;
      color: #127CC0;
      font-weight: 600;
    }
    .itemBox{
      padding: 2vw 0 3vw 0;
      display: block;
      .item{
        margin-bottom: 5vw;
        .img{
          width: 100%;
          height: 46.5vw !important;
        }
        .text{
          font-size: 4.5vw;
          color: #333;
          padding: 3vw 0 2vw 0;
        }
        .time{
          font-size: 3vw;
          color: #999999;
        }
      }
    }
  }
}
.w1{
  position: absolute;
  top: 0;
  left: 0;
  width: 60vw;
}
.w2{
  position: absolute;
  top: 30%;
  right: 0;
  width: 60vw;
}
.w3{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60vw;
  z-index: 0;
}
</style>